import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material"

export const DialogDoc = ({name, docOpen, setDocOpen, socket}) => {

  const handleDocClose = () => {
    setDocOpen(false)
  }

  const handleSubmit = () => {
    if (!socket) return
    socket.send(
      JSON.stringify({"type": "agreement-send"})
    )
    setDocOpen(false)
  }

  return (
    <Dialog open={docOpen} onClose={handleDocClose}>
      <DialogTitle>Custom Fact</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Do you want to send a document to <b>{name}</b> for signing?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleDocClose}>No</Button>
        <Button onClick={handleSubmit}>Yes</Button>
      </DialogActions>
    </Dialog>
  )
}