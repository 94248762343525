import { createTheme } from "@mui/material"

// Self colors
const main = "#0E1C42"
const grad1 = "#00C78B"
const grad2 = "#71B4FF"
const appMsg = "#CBDFFD"

export const Theme = createTheme({
  palette: {
    primary: {
      main: main,
    },
    background: {
      grad1: grad1,
      grad2: grad2,
      appMsg: appMsg,
    },
  },
})
